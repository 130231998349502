import React, { Fragment, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'
import getTheme from './buildTheme'
import moment from 'moment-timezone'
import Routes from './routes/index'
import { BrowserRouter } from 'react-router-dom'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import useThunkDispatch from 'CustomHooks/useThunkDispatch'
import { getUrlParams } from 'CommonComponents'
import { setUrlPrefix } from 'Actions/account_action'
import { getRegions } from 'Actions/center_action'
import { store } from './store.index'
import WebFont from 'webfontloader'
import { getFontName } from './utils/helper'
import Footer from './components/Footer'
import Loader from 'Components/Loader'
import UnderService from 'Pages/UnderService'
import NavBar from './components/NavBar'
import useQuery from './custom-hooks/useQuery'

const GlobalStyle = createGlobalStyle`
  body {
    color: ${props => props.theme.color.brand};
    background-color: ${props => props.theme.color.bg};
    font-family: ${props => props.theme.fontFamily};
    font-size: 1rem;
    line-height: 1.25em;
    padding: 0 24px;

    @media (max-width: ${props => props.theme.breakpoint.md}px) {
      padding: 0 12px;
    }
  }
  h1,h2,h3,h4,h5,p,span,section,article,ul,li {
    margin:0;
    padding:0;
  }
`

const App = ({ regions }) => {
  const thunkDispatch = useThunkDispatch()
  const [theme, setTheme] = useState(null)
  const [wafLoading, setWafLoading] = useState(true)

  const renderServicePage = useSelector(
    store => store.websiteReducer.renderServicePage
  )
  const apps = useSelector(
    store => store.centerreducer.regionsDetails?.client?.apps
  )

  const centers = useSelector(store => store.centerreducer)

  useEffect(() => {
    const getWafToken = async () => {
      try {
        if (typeof window.AwsWafIntegration !== 'undefined') {
          if (!window.AwsWafIntegration.hasToken()) {
            const token = await window.AwsWafIntegration.getToken()
            localStorage.setItem('aws-waf-token', token)
          }
        }
      } catch (err) {
        console.log('wftkn ~ err:', err?.message ?? err)
      } finally {
        setWafLoading(false)
      }
    }
    getWafToken()
  }, [])

  useEffect(() => {
    if (!wafLoading) {
      const url_prefix = getUrlParams()
      setUrlPrefix(url_prefix)(store.dispatch)
      thunkDispatch(getRegions({ url_prefix }))
    }
  }, [wafLoading])

  const initializeGA = trackingID => {
    if (trackingID) {
      ReactGA.initialize(trackingID, {
        gtagOptions: {
          cookie_flags: 'samesite=none;secure'
        }
      })
    }
  }

  const initializeMP = trackingID => {
    if (trackingID) {
      const options = {
        autoConfig: true,
        debug: false
      }
      ReactPixel.init(trackingID, null, options)
    }
  }

  useEffect(() => {
    if (apps && Object.keys(apps).length > 0) {
      if (apps.google_analytics)
        initializeGA(apps.google_analytics?.tracking_id)
      if (apps.meta_pixel) initializeMP(apps.meta_pixel?.tracking_id)
    }
  }, [apps])

  useEffect(() => {
    if (centers) {
      const centersById = centers.centersById
      const regionsById = centers.regionsById
      const regionId = parseInt(localStorage.getItem('region_id') || '0')
      const locationId = parseInt(
        localStorage.getItem('selected_center_id') || '0'
      )

      if (regionsById && regionId) {
        const regionName = regionsById[regionId]?.name
        localStorage.setItem('regionName', regionName)
      }
      if (centersById && locationId) {
        const locationName = centersById[locationId]?.branch
        localStorage.setItem('locationName', locationName)
      }
    }
  }, [centers])

  useEffect(() => {
    if (regions && regions.length > 0) {
      moment.tz.setDefault(regions[0].timezone)
      let {
        brand_color,
        background_color,
        text_color,
        font_family
      } = regions[0].centers[0].settings.general
      const fontFamily = getFontName(font_family)
      WebFont.load({
        google: {
          families: [`${fontFamily.loaderName}:400,700`]
        }
      })
      setTheme(
        getTheme({
          brand: brand_color,
          bg: background_color,
          primaryText: text_color,
          fontFamily: fontFamily.applyName
        })
      )
    }
  }, [regions])

  if (renderServicePage)
    return (
      <>
        <UnderService />
        <Footer />
      </>
    )

  if (wafLoading || theme === null || regions?.length === 0) {
    return (
      <div>
        <Loader />
      </div>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <GlobalStyle />
        <BrowserRouter>
          <NavBar />
          <Routes />
        </BrowserRouter>
        <Footer theme={theme} />
      </Fragment>
    </ThemeProvider>
  )
}

const mapStateToProps = state => {
  return {
    regions: state.centerreducer.regionsDetails
      ? state.centerreducer.regionsDetails.regions
      : null
  }
}

export default connect(mapStateToProps, null)(App)
