import libphonenumber from 'google-libphonenumber'
import moment from 'moment'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'
const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()

export const validatePassword = password => password.length >= 8
export const isEqualDate = (date1, date2) => {
  return (
    moment(date1).format('YYYY-MM-DD') === moment(date2).format('YYYY-MM-DD')
  )
}

export const validatePhoneNumberGoogleLib = ({ phone, country }) => {
  return phoneUtil.isValidNumberForRegion(
    phoneUtil.parse(phone, country.toUpperCase()),
    country.toUpperCase()
  )
}

export const validateEmail = email => {
  const regex = /\S+@\S+\.\S{2,}/
  return regex.test(email)
}

export const isFormValidate = (allFields = [], statuses = {}) => {
  return allFields.every(
    field => statuses[field] && statuses[field].type === 'success'
  )
}

export const isFormValidateSignUp = (allFields = [], statuses = {}) => {
  return allFields.every(field => {
    if (field == 'flex' || field == 'affiliate_id') {
      return true
    } else {
      return statuses[field] && statuses[field].type === 'success'
    }
  })
}

export const inIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export const hexToRgbA = (hex, alpha) => {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      alpha +
      ')'
    )
  }
  throw new Error('Bad Hex')
}

/**
 * Map array of objects by a key value
 * @param {Array} arr
 * @param {String} key
 * @returns {Object}
 */
export function mapByValue(arr, key) {
  return arr.reduce((acc, ob) => ({ ...acc, [ob[key]]: { ...ob } }), {})
}

export class Queue {
  constructor() {
    this.queue = []
  }

  get length() {
    return this.queue.length
  }

  enqueue(item) {
    return this.queue.unshift(item)
  }

  dequeue() {
    return this.queue.pop()
  }

  isEmpty() {
    return this.length === 0
  }

  peek() {
    return this.queue[this.length - 1]
  }
}

export function durationFromSec(sec, enable_format = false, format = 'h:m:s') {
  let data = { h: 0, m: 0, s: 0 }
  if (!sec) {
    sec = 0
  }
  let us = Math.floor(sec)
  let um = Math.floor(sec / 60)
  let uh = Math.floor(um / 60)
  let s = us - um * 60
  let m = um - uh * 60
  let h = uh

  if (h < 10) {
    h = '0' + h
  }
  if (m < 10) {
    m = '0' + m
  }
  if (s < 10) {
    s = '0' + s
  }
  let formated = ''
  if (h === '00') {
    formated = format
      .replace('h', '')
      .replace(':', '')
      .replace('m', m)
      .replace('s', s)
  } else {
    formated = format
      .replace('h', h)
      .replace('m', m)
      .replace('s', s)
  }
  data = { h, m, s }

  if (enable_format) {
    data = formated
  }
  return data
}

export const dataURLtoFile = async (dataurl, filename) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

export const stringify_names = data => {
  return data
    .trim()
    .replace(' ', '-')
    .toLowerCase()
}

export const isTextClamped = element => {
  if (!element) return false
  return element.scrollHeight > element.clientHeight
}

export const getFontName = font => {
  const lowerCaseFont = stringify_names(font)
  switch (lowerCaseFont) {
    case 'muli':
      return {
        loaderName: 'Mulish',
        applyName: 'Mulish, sans-serif'
      }
    case 'multi':
      return {
        loaderName: 'Mulish',
        applyName: 'Mulish, sans-serif'
      }
    case 'lato':
      return {
        loaderName: 'Lato',
        applyName: 'Lato, sans-serif'
      }
    case 'raleway':
      return {
        loaderName: 'Raleway',
        applyName: 'Raleway, sans-serif'
      }
    case 'montserrat':
      return {
        loaderName: 'Montserrat',
        applyName: 'Montserrat, sans-serif'
      }
    case 'source-sans-pro':
      return {
        loaderName: 'Source Sans Pro',
        applyName: 'Source Sans Pro, sans-serif'
      }
    case 'source-sans':
      return {
        loaderName: 'Source Sans Pro',
        applyName: 'Source Sans Pro, sans-serif'
      }
    case 'open-sans':
      return {
        loaderName: 'Open Sans',
        applyName: 'Open Sans, sans-serif'
      }
    case 'playfair-display':
      return {
        loaderName: 'Playfair Display',
        applyName: 'Playfair Display, serif'
      }
    default:
      return {
        loaderName: font.split(',')[0].trim(),
        applyName: font
      }
  }
}

export const getExpiryText = (duration, durationType) =>
  `${duration} ${durationType}${duration > 1 ? 's' : ''}`

/**
 * Send events for tracking
 * @param {object} param0 category, action, label
 * @param {object} extraOptions extra properties
 */
export const trackEvent = ({ category, action, label }, extraOptions = {}) => {
  const apps = JSON.parse(localStorage.getItem('clientSettings'))?.apps
  const regionName = localStorage.getItem('regionName')
  const locationName = localStorage.getItem('locationName')

  const options = { regionName, locationName, ...extraOptions }

  if (apps) {
    if (apps.google_analytics) {
      ReactGA.gtag('event', label, { action, ...options })
    }
    if (apps.meta_pixel) {
      ReactPixel.trackCustom(category, { action, label, ...options })
    }
  }
}

/**
 * Rounds upto 2 digits
 * @param {number} num number to be rounded
 * @returns rounded number
 */
export const getRoundedNumber = num =>
  Math.round((num + Number.EPSILON) * 100) / 100

/**
 *  Opens google maps with the addreess as query
 * @param {string} address address to be searched
 */
export const openGoogleMaps = ({ address, city, state, pincode, country }) => {
  const url = `https://www.google.com/maps/search/?api=1&query=${address}, ${city}, ${state} ${pincode}, ${country}`
  window.open(url, '_blank')
}

/**
 * Returns the installment amount
 * @param {number} totalAmount total amount
 * @param {number} initialAmount initial amount
 * @param {number} numInstallments number of installments
 * @returns installment amount
 * */
export const getInstallmentAmount = (
  totalAmount,
  initialAmount,
  numInstallments
) => {
  const installmentAmount = (totalAmount - initialAmount) / numInstallments
  return getRoundedNumber(installmentAmount)
}

/**
 * Returns the payment plan details
 * @param {number} totalAmount total amount
 * @param {number} initialAmount initial amount
 * @param {number} numInstallments number of installments
 * @param {number} duration duration
 * @param {string} durationType duration type
 * @returns payment plan details
 * */
export const generatePaymentPlanDetails = (
  totalAmount,
  initialAmount,
  numInstallments,
  duration,
  durationType
) => {
  const installmentAmount = getInstallmentAmount(
    totalAmount,
    initialAmount,
    numInstallments
  )

  let currentPaymentDate = moment()

  const paymentPlan = [
    {
      amount: initialAmount,
      paymentDate: currentPaymentDate.format('MMM DD YYYY'),
      installmentNumber: 'Initial Payment'
    }
  ]
  for (let i = 0; i < numInstallments; i++) {
    currentPaymentDate = moment(currentPaymentDate).add(duration, durationType)

    paymentPlan.push({
      amount: installmentAmount,
      paymentDate: currentPaymentDate.format('MMM DD YYYY'),
      installmentNumber: `Installment ${i + 1}/${numInstallments}`
    })
  }

  return paymentPlan
}

export const mapSharedCentersWithName = ({
  shareCenters,
  centerName,
  centersById,
  region,
  showAll = false
}) => {
  if (shareCenters?.length > 0) {
    //truncate the list if greater than 3 and add + {{rem_locations}} other lcoations
    const centers = [...new Set(shareCenters)]

    if (centers.length === region?.centers?.length) return region.name

    return centers
      ?.slice(0, showAll ? centers.length : 2)
      .map(centerId => centersById?.[centerId]?.branch)
      .join(', ')
      .concat(
        !showAll && centers.length > 2
          ? centers.length === 3
            ? ` & ${centersById?.[centers[2]]?.branch}`
            : ` + ${centers.length - 2} other locations`
          : ''
      )
  }
  return centerName
}

export const rotateArrayByOne = arr => {
  if (arr.length <= 1) {
    return [...arr]
  }

  const lastElement = arr[arr.length - 1]
  return [lastElement, ...arr.slice(0, arr.length - 1)]
}

export const getCookie = name => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2)
    return parts
      .pop()
      .split(';')
      .shift()
}

export const setCookie = (name, value, days) => {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = `; expires=${date.toUTCString()}`
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`
}

export const getVisibleDatesUtil = weekStart => {
  const startDate = moment().format('YYYY-MM-DD')
  const endDate =
    weekStart === 0
      ? moment()
          .add(1, 'weeks')
          .day(6)
          .format('YYYY-MM-DD')
      : moment()
          .add(1, 'weeks')
          .day(0)
          .format('YYYY-MM-DD')

  return { startDate, endDate }
}
