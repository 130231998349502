import axios from 'axios'
import posthog from 'posthog-js'

import { logoutUser } from 'Actions/account_action'
import { store } from '../store.index'
import { setServicePage } from './website_actions'

function client() {
  axios.interceptors.request.use(
    config => {
      const awsWafToken = localStorage.getItem('aws-waf-token')
      const rwgToken = localStorage.getItem('rwg_token')

      if (config.data instanceof FormData) {
        config.headers['content-type'] = 'multipart/form-data'
      }
      config.headers = {
        ...config.headers,
        'x-aws-waf-token': awsWafToken,
        'x-rwg-token': rwgToken || ''
      }
      if (config.data instanceof FormData) {
        config.headers['content-type'] = 'multipart/form-data'
      }
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    response => {
      setServicePage(false)(store.dispatch)
      return response
    },
    error => {
      posthog.capture(error.name, {
        message: error?.message ?? 'Something went wrong!',
        status: error?.response?.status,
        url: error?.response?.config?.url
      })
      if (error.response?.status === 401) {
        logoutUser()(store.dispatch)
        window.location = '/login'
      } else if (error.response?.status === 503) {
        setServicePage(true)(store.dispatch)
      }
      return Promise.reject(error)
    }
  )

  return axios
}

export { client }
